import React from "react"

import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

export const Label = styled.p`
  text-align: center;
  margin-top: 1rem;
  font-weight: 600;
`
export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding: 0 1rem;
  flex: 1;
  transition: all 200ms ease-in-out;
  &:hover {
    transform: ${props => (props.noHover ? "none" : " translateY(-4px)")};
    transform: ${props => (props.noHover ? "none" : "scale(1.1)")};
  }
`
export const DoorContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
`
export const DisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const AutomaticDoor = () => {
  const automaticDoors = ["RAL", "Panoramic", "Skin Plate", "Steel"]
  const doorLabel = automaticDoors.map(door => door)

  return (
    <StaticQuery
      query={graphql`
        {
          allFile(filter: { name: { regex: "/AD/" } }) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(
                    height: 400
                    blurredOptions: { width: 100 }
                    placeholder: BLURRED
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      `}
      render={data =>
        data.allFile.edges.map(({ node }, index) => (
          <ImgContainer>
            <GatsbyImage
              key={index}
              alt={doorLabel[index]}
              image={getImage(node.childImageSharp.gatsbyImageData)}
            ></GatsbyImage>

            <Label>{doorLabel[index]}</Label>
          </ImgContainer>
        ))
      }
    />
  )
}

export default AutomaticDoor
