import React from "react"
import { useState } from "react"
import Container from "../../styles/styled-component/Container"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import Section from "../../styles/styled-component/Section"
import { GalleryGrid } from "./finishElements"
import Inox from "./inox"
import Laminate from "./laminate"
import Pelle from "./pelle"
import Plalam from "./plalam"
import Ral from "./ral"
import Wood from "./wood"
import { LuxurySection } from "../luxury/luxuryComponents"
import bgImg from "../../images/finishes-bg.jpg"
import {
  ButtonWrapper,
  Button,
  ArrowForward,
  ArrowRight,
} from "../../styles/styled-component/Button"
import { StaticImage } from "gatsby-plugin-image"
import { Trans } from "gatsby-plugin-react-i18next"
const FinishesComponent = () => {
  const [hover, setHover] = useState(false)
  const onHover = () => {
    setHover(!hover)
  }
  return (
    <>
      <LuxurySection flex bgImage={bgImg}>
        <Container flex center>
          <InnerContainer half>
            <h1>
              <Trans>Divas Finishes</Trans>
            </h1>
            <p>
              <Trans>
                Check out our cabin finishes, choose between different solutions
                such as: Inox, Laminate, Ral, Plalam or check out our special
                luxury solutions
              </Trans>
            </p>
            <ButtonWrapper centerButton>
              <Button
                to="/luxury-design"
                bgColor="#0066ff"
                bgColorHover="#005ce6"
                borderRadius="24px"
                onMouseEnter={onHover}
                onMouseLeave={onHover}
              >
                <Trans>Luxury solutions</Trans>
                {hover ? <ArrowForward /> : <ArrowRight />}
              </Button>{" "}
            </ButtonWrapper>
          </InnerContainer>
          <InnerContainer half>
            <StaticImage
              src="../../images/Finishes.png"
              placeholder="blurred"
            />
          </InnerContainer>
        </Container>
      </LuxurySection>
      <Section>
        <Container>
          <InnerContainer>
            <h1>
              <Trans>Stainless steel</Trans>
            </h1>
            <p>
              <Trans>
                Elegant and resistant, this special stainless steel alloy
                protects and gives your elevator a sleek look
              </Trans>{" "}
            </p>
            <GalleryGrid>
              <Inox />
            </GalleryGrid>
          </InnerContainer>
        </Container>
        <Container>
          <InnerContainer>
            <h1>
              <Trans>Plalam SkinPlate</Trans>
            </h1>
            <p>
              <Trans>
                Skinplate made by worldwide leading Italian companies, easy to
                clean and practical it recalls the effect of more sophisticated
                materials
              </Trans>{" "}
            </p>
            <GalleryGrid>
              <Plalam />{" "}
            </GalleryGrid>
          </InnerContainer>
        </Container>
        <Container>
          <InnerContainer>
            <h1>
              <Trans>Laminates</Trans>
            </h1>
            <p>
              <Trans>
                These plastic laminates are perfect to suit every need,
                available in different colors and textures
              </Trans>
            </p>
            <GalleryGrid>
              <Laminate />{" "}
            </GalleryGrid>
          </InnerContainer>
        </Container>{" "}
        <Container>
          <InnerContainer>
            <h1>
              <Trans></Trans>Leather
            </h1>
            <p>
              <Trans></Trans>A wide variety of textures and colors for our
              leather finishes, perfect to reprude the styles of the building
              furniture
            </p>
            <GalleryGrid>
              <Pelle />{" "}
            </GalleryGrid>
          </InnerContainer>
        </Container>
        <Container>
          <InnerContainer>
            <h1>
              <Trans>Wood</Trans>
            </h1>
            <p>
              <Trans>
                Give a exclusive finish to your elevator, available in different
                textures and effects, define the style of your elevator, choose
                between a modern or more classic finish
              </Trans>
            </p>
            <GalleryGrid>
              <Wood />{" "}
            </GalleryGrid>
          </InnerContainer>
        </Container>
        <Container>
          <InnerContainer>
            <h1>
              <Trans>Ral Paint"</Trans>
            </h1>
            <p>
              <Trans>
                Powered paint, very resistant and safe finish, available in many
                colors
              </Trans>{" "}
            </p>
            <GalleryGrid>
              <Ral />{" "}
            </GalleryGrid>
          </InnerContainer>
        </Container>
      </Section>
    </>
  )
}

export default FinishesComponent
