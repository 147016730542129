import styled from "styled-components"

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const FinishContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`

export const CeilingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const CeilingLabel = styled.p`
  padding: 1rem;
  font-weight: 600;
  text-transform: capitalize;
`
