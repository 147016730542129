import styled from "styled-components"

export const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr 1fr;
  }
`
