import React from "react"
import FinishesComponent from "../components/finishes"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
const Finshes = () => {
  return (
    <Layout>
      <Seo title="Finishes" />
      <FinishesComponent />
    </Layout>
  )
}

export default Finshes

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
